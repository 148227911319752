.servicios-section {
  padding: 40px 20px;
  padding-left: 8%;
  padding-right: 8%;
}

.servicios-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  text-align: left;
}

.servicios-item {
  background-color: #ffffff;
  padding: 20px;
}

.servicios-item-white {
  background-color: #ffffff;
}

.servicios-item-gray {
  background-color: #e2edf4;
}

.servicios-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.servicios-item-white > h1 {
  margin-top: 20px;
}

.servicios-item-white > p {
  margin-top: 10px;
}

.servicios-item-gray > p {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .servicios-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .servicios-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
