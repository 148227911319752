.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #ffffff;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.logo img {
  height: 64px;
}

.nav-menu {
  position: relative;
}

.menu-toggle {
  display: none;
}

.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  cursor: pointer;
  z-index: 100;
}

.menu-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #004054;
  transition: all 0.3s ease-in-out;
}

.nav-links {
  display: flex;
  gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links a {
  color: #004054;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 200;
    opacity: 0;
  }

  .menu-icon {
    display: flex;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%; /* Update this line to 100% */
    right: 0%;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 0; /* Update this line to 0 */
    z-index: 999;
  }

  .nav-links a {
    color: #004054;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .nav-links a:hover {
    text-decoration: underline;
  }

  #menu-toggle:checked ~ .nav-links {
    display: flex;
    padding: 50px;
  }
}
