.footer-section {
  background-color: white;
  padding-left: 8%;
  padding-right: 8%;
}

.footer-row {
  display: flex;
}

.footer-column {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.footer-left-column {
  background-color: #008eaa;
}

.footer-middle-column {
  background-color: #64ccc9;
}

.footer-right-column {
  background-color: #004054;
}

.footer-icon {
  /* Add styling for the icons */
}

.footer-column-heading {
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
}

.footer-column-subheading {
  color: #ffffff;
  font-size: 18px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .footer-row {
    flex-wrap: wrap;
  }

  .footer-column {
    flex-basis: 100%;
  }
}
