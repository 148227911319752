.cuerpo-medico-section {
  padding: 40px 20px;
}

.row-medico {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.column-photo {
  padding: 10px;
}

.person-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #37b8df;
  /* Add photo styling as needed */
}

.section-heading {
  color: #234b62;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.section-subheading {
  color: #37b8df;
  font-size: 18px;
  margin-top: 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
}

.grid-item {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.grid-heading {
  color: #234b62;
  font-size: 24px;
  font-weight: bold;
}

.grid-subheading {
  color: #37b8df;
  font-size: 18px;
}

@media (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
