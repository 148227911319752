.quienes-somos-section {
  padding: 40px 20px;
  background-color: white;
  padding-left: 8%;
  padding-right: 8%;
}

.section-header {
  font-size: 28px;
  font-weight: bold;
  text-align: left;
}

.quienes-somos-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.column-inner {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: white;
  margin-bottom: 20px;
}

.column-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  object-fit: contain;
}

.column-heading {
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.column-paragraph {
  text-align: center;
  margin-top: 5px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .quienes-somos-columns {
    flex-direction: column;
  }

  .column-inner {
    flex-basis: 100%;
    margin-right: 0;
  }
}
