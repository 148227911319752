.main-banner {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.left-column {
  width: 100%;
}

.right-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.row-top {
  color: white;
  background-color: #64ccc9;
}

.row-middle {
  color: white;
  background-color: #008eaa;
}

.row-bottom {
  color: white;
  background-color: #004054;
}

.bottom-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 8%;
  padding-right: 8%;
}

.column-main {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.logos img {
  margin: 5px;
}

.carousel .slider-wrapper {
  overflow: hidden;
  width: 100%;
}

.banner-image {
  width: 100%;
}

.banner-video {
  width: 100%;
}

.btn {
  padding: 15px;
  color: white;
  background-color: #f4b348;
  border: none;
}
